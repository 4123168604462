<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="suppliers_ids">{{ $t('purchase_report.suppliers') }}</label>
                <multiselect id="suppliers_ids"
                             v-model="suppliers_ids"
                             :placeholder="$t('purchase_report.suppliers')"
                             label="business_name"
                             track-by="id"
                             :options="suppliers"
                             :multiple="true"
                             @input="getIds('suppliers')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="item_id">{{ $t('profit_of_sales_item_report.items') }}</label>
                <div class="d-flex search-item-group">
                  <select v-model="f_by" class="custom-select" name="" style="width: 100px;">
                    <option selected value="name">{{ $t('items.name') }}</option>
                    <option value="sku_code">{{ $t('items.sku_code') }}</option>
                  </select>
                  <multiselect id="item_id"
                               v-model="item"
                               :internal-search="false"
                               :multiple="false"
                               :options="items"
                               :label="f_by?f_by:'sku_code'"
                               @input="getIds('item')"
                               :placeholder="$t('profit_of_sales_item_report.items')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="false"
                               track-by="id"
                               @search-change="getItems(f_by, $event)">
                  </multiselect>
                </div>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('purchase_report.added_by') }}</label>
                <multiselect id="user_id"
                             v-model="user"
                             :placeholder="$t('purchase_report.added_by')"
                             label="name"
                             track-by="id"
                             :options="users"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.branches') }}</label>
                <multiselect v-model="branches_ids"
                             :placeholder="$t('customer_transactions_report.branches')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="true"
                             @input="getIds('branches')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="f_status">{{ $t('purchase_report.payment_status') }}</label>
                <select name="" id="f_status" v-model="is_paid" type="text" class="custom-select">
                  <option value="">{{ $t('all') }}</option>
                  <option v-for="(row, index) in status_list" :value="row.id" :key="'status'+index">{{ row.title }}</option>
                </select>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('purchase_report.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('purchase_report.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button  @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_date')}}</b-button>
                    <b-button  @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_month')}}</b-button>
                    <b-button  @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_year')}}</b-button>
                    <b-button  @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button  @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.yesterday')}}</b-button>
                    <b-button  @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_month')}}</b-button>
                    <b-button  @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_year')}}</b-button>
                  </b-button-group>

                </div>
              </div>



              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab :title="$t('summery')" :active="tab == 'summery' || tab == null">
            <div class="row mb-10">
              <div class="col-md-12 mt-10" id="summaryTableTitle">
                <h4 class="text-center">{{ $t('purchase_report.purchase_report') }}</h4>
                <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
              </div>
<!--              <div class="col-md-6 mt-10" v-if="line_invoice_details">-->
<!--                <LineChart :data-line-invoice="line_invoice_details" :currency-name="currency_name"/>-->
<!--              </div>-->
<!--              <div class="col-md-6 mt-10" v-if="paid_unpaid_details">-->
<!--                <DoughnutChart :data-paid-unpaid="paid_unpaid_details" :currency-name="currency_name"/>-->
<!--              </div>-->
            </div>
            <div class="row mb-10">
              <div class="col-12" v-if="data_report_details">
                <div class="table-responsive">
                  <div class="row justify-content-end p-4">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
<!--                      <download-excel-->
<!--                          class="dropdown-item"-->
<!--                          :fetch="salesInvoiceExport"-->
<!--                          :fields="json_fields"-->
<!--                          :name="$t('MENU.purchases_invoices_segmented_Reports')+'.xls'">-->
<!--                        <i class="la la-file-excel"></i>{{ $t('excel') }}-->
<!--                      </download-excel>-->
                      <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printPDFData('summaryTable','pdf')">
                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="getAllDataForExportSummary('sectionForExportSummary', 'excel')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
                  <table class="table table-bordered" id="summaryTable">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t('purchase_report.'+periodLabel) }}</th>
                      <th>{{ $t('purchase_report.paid') }}</th>
                      <th>{{ $t('purchase_report.unpaid') }}</th>
                      <th>{{ $t('purchase_report.refund') }}</th>
                      <th>{{ $t('purchase_report.total') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row2, index2) in data_report_summary" :key="'a'+getRandom()+index2">
                      <td>{{ index2 + 1 }}</td>
                      <td>{{ row2.label }}</td>
                      <td>{{ row2.total_paid_amount }}</td>
                      <td>{{ row2.remaining_amount }}</td>
                      <td>{{ row2.refund_amount }}</td>
                      <td>{{ row2.total }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td colspan="2"><b>{{ $t('purchase_report.total') }}</b></td>
                      <th>{{ summary_total_sum ? summary_total_sum.total_paid_amount : 0 }}</th>
                      <th>{{ summary_total_sum ? summary_total_sum.remaining_amount : 0 }}</th>
                      <th>{{ summary_total_sum ? summary_total_sum.refund_amount : 0 }}</th>
                      <th>{{ summary_total_sum ? summary_total_sum.total : 0 }}</th>
                    </tr>
                    </tfoot>
                  </table>
                </div>

              </div>
                <div class="col-12 mt-5 text-center">
                  <button class="btn btn-warning" @click="loadMoreSummary">
                    <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                    {{ $t('load_more') }}
                  </button>
                </div>

            </div>
          </b-tab>

          <b-tab :title="$t('details')" :active="tab == 'details'">
            <div class="mt-3">
              <div class="row mb-10">
                <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                  <h4 class="text-center">{{ $t('purchase_report.purchase_report') }}</h4>
                  <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                </div>
<!--                <div class="col-md-6 mt-10" v-if="line_invoice_details">-->
<!--                  <LineChart :data-line-invoice="line_invoice_details" :currency-name="currency_name"/>-->
<!--                </div>-->
<!--                <div class="col-md-6 mt-10" v-if="paid_unpaid_details">-->
<!--                  <DoughnutChart :data-paid-unpaid="paid_unpaid_details" :currency-name="currency_name"/>-->
<!--                </div>-->
              </div>
              <div class="row mb-10">
                <div class="col-12" v-if="data_report_details">
                  <div class="table-responsive">
                    <div class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
<!--                        <download-excel-->
<!--                            class="dropdown-item"-->
<!--                            :fetch="salesInvoiceDetailsExport"-->
<!--                            :fields="json_fields_details"-->
<!--                            :name="$t('MENU.purchases_invoices_segmented_Reports')+'.xls'">-->
<!--                          <i class="la la-file-excel"></i>{{ $t('excel') }}-->
<!--                        </download-excel>-->

                        <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="printData('summaryTableDetails', 'print')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="printPDFData('summaryTableDetails', 'pdf')">
                          <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="getAllDataForExport('sectionForExport', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>
                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="summaryTableDetails">
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>{{ $t('purchase_report.document') }}</th>
                        <th v-if="!isPeriod">{{ $t('purchase_report.date') }}</th>
                        <th v-if="filters.filter_by != 'user'">{{ $t('purchase_report.user') }}</th>
                        <th v-if="filters.filter_by != 'supplier'">{{ $t('purchase_report.supplier') }}</th>
                        <th>{{ $t('purchase_report.paid') }}</th>
                        <th>{{ $t('purchase_report.unpaid') }}</th>
                        <th>{{ $t('purchase_report.refund') }}</th>
                        <th>{{ $t('purchase_report.total') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(row, index) in data_report_details">
                        <tr>
                          <td :colspan="!isPeriod ? 9 : 8" style="text-align: start !important;">{{ row.label }}</td>
                        </tr>
                        <tr v-for="(row2, index2) in row.details" :key="'ammm'+getRandom()+index2">
                          <td>{{ index2 + 1 }}</td>
                          <td> {{row2.document_name}} {{ row2.code }}</td>
                          <td v-if="!isPeriod"> {{ row2.created_at }}</td>
                          <td v-if="filters.filter_by != 'user'">{{ row2.label }}</td>
                          <td v-if="filters.filter_by != 'supplier'">{{ row2.supplier_name }}</td>
                          <td>{{ row2.total_paid_amount }}</td>
                          <td>{{ row2.remaining_amount }}</td>
                          <td>{{ row2.refund_amount }}</td>
                          <td>{{ row2.total }}</td>
                        </tr>
                        <tr>
                          <td :colspan="4">{{ $t('purchase_report.total') }}</td>
                          <td>{{ row.total_paid_amount }}</td>
                          <td>{{ row.remaining_amount }}</td>
                          <td>{{ row.refund_amount }}</td>
                          <td>{{ row.total }}</td>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <td :colspan="4"><b>{{ $t('purchase_report.total') }}</b></td>
                        <th>{{ details_total_sum ? details_total_sum.total_paid_amount : 0 }}</th>
                        <th>{{ details_total_sum ? details_total_sum.remaining_amount : 0 }}</th>
                        <th>{{ details_total_sum ? details_total_sum.refund_amount : 0 }}</th>
                        <th>{{ details_total_sum ? details_total_sum.total : 0 }}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
              </div>
              <div class="col-12 mt-5 text-center">
                <button class="btn btn-warning" @click="loadMore">
                  <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                  {{ $t('load_more') }}
                </button>
              </div>

            </div>
          </b-tab>
          <template #tabs-end>
            <li class="nav-item  d-flex my-auto">
              <select name="" id="" v-model="filters.filter_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none">
                <option :value="null" disabled>{{ $t('select_filter') }}</option>
                <option value="daily">{{ $t('daily') }}</option>
                <option value="weekly">{{ $t('weekly') }}</option>
                <option value="monthly">{{ $t('monthly') }}</option>
                <option value="yearly">{{ $t('yearly') }}</option>
                <option value="supplier">{{ $t('supplier') }}</option>
                <option value="user">{{ $t('employee') }}</option>
              </select>

            </li>
          </template>
        </b-tabs>



      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExportSummary">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('purchase_report.'+periodLabel) }}</th>
            <th>{{ $t('purchase_report.paid') }}</th>
            <th>{{ $t('purchase_report.unpaid') }}</th>
            <th>{{ $t('purchase_report.refund') }}</th>
            <th>{{ $t('purchase_report.total') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row2, index2) in dataSummaryList" :key="'rrrra'+getRandom()+index2">
            <td>{{ index2 + 1 }}</td>
            <td>{{ row2.label }}</td>
            <td>{{ row2.total_paid_amount }}</td>
            <td>{{ row2.remaining_amount }}</td>
            <td>{{ row2.refund_amount }}</td>
            <td>{{ row2.total }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colspan="2"><b>{{ $t('purchase_report.total') }}</b></td>
            <th>{{ totalsSummaryList ? totalsSummaryList.total_paid_amount : 0 }}</th>
            <th>{{ totalsSummaryList ? totalsSummaryList.remaining_amount : 0 }}</th>
            <th>{{ totalsSummaryList ? totalsSummaryList.refund_amount : 0 }}</th>
            <th>{{ totalsSummaryList ? totalsSummaryList.total : 0 }}</th>
          </tr>
          </tfoot>
        </table>

        <table class="table table-bordered" id="sectionForExport">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('purchase_report.document') }}</th>
            <th v-if="!isPeriod">{{ $t('purchase_report.date') }}</th>
            <th v-if="filters.filter_by != 'user'">{{ $t('purchase_report.user') }}</th>
            <th v-if="filters.filter_by != 'supplier'">{{ $t('purchase_report.supplier') }}</th>
            <th>{{ $t('purchase_report.paid') }}</th>
            <th>{{ $t('purchase_report.unpaid') }}</th>
            <th>{{ $t('purchase_report.refund') }}</th>
            <th>{{ $t('purchase_report.total') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataDetailsList">
            <tr>
              <td :colspan="!isPeriod ? 9 : 8" style="text-align: start !important;">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.details" :key="'afffff'+getRandom()+index2+index">
              <td>{{ index2 + 1 }}</td>
              <td> {{row2.document_name}} {{ row2.code }}</td>
              <td v-if="!isPeriod"> {{ row2.created_at }}</td>
              <td v-if="filters.filter_by != 'user'">{{ row2.label }}</td>
              <td v-if="filters.filter_by != 'supplier'">{{ row2.supplier_name }}</td>
              <td>{{ row2.total_paid_amount }}</td>
              <td>{{ row2.remaining_amount }}</td>
              <td>{{ row2.refund_amount }}</td>
              <td>{{ row2.total }}</td>
            </tr>
            <tr>
              <td :colspan="4">{{ $t('purchase_report.total') }}</td>
              <td>{{ row.total_paid_amount }}</td>
              <td>{{ row.remaining_amount }}</td>
              <td>{{ row.refund_amount }}</td>
              <td>{{ row.total }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr>
            <td :colspan="4"><b>{{ $t('purchase_report.total') }}</b></td>
            <th>{{ totalsDetailsList ? totalsDetailsList.total_paid_amount : 0 }}</th>
            <th>{{ totalsDetailsList ? totalsDetailsList.remaining_amount : 0 }}</th>
            <th>{{ totalsDetailsList ? totalsDetailsList.refund_amount : 0 }}</th>
            <th>{{ totalsDetailsList ? totalsDetailsList.total : 0 }}</th>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="selector-export" id="selectorExport"></div>
    <!--end::supplier-->


    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
<!--        <report-main-header :print-type="'PurchaseBySupplierReport'"></report-main-header>-->
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import DoughnutChart from './components/Doughnut.vue';
import LineChart from './components/Line.vue';
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-purchase-by-supplier-report",
  components: {ReportMainHeader, DoughnutChart, LineChart},
  data() {
    return {
      mainRoute: '/reports/purchases/supplier',
      mainRouteDependency: 'base/dependency',

      currency_name: null,
      supplier: null,

      line_invoice_details: [],
      paid_unpaid_details: [],

      data_report_details: [],
      details_total_sum: [],

      data_report_summary: [],
      summary_total_sum: [],

      dataDetailsList: [],
      totalsDetailsList: [],

      dataSummaryList: [],
      totalsSummaryList: [],


      filters: {
        suppliers_ids: null,
        item_id: null,
        from_date: null,
        to_date: null,
        user_id: null,
        overdue: null,
        f_status: null,
        filter_by: 'daily',
        branches_ids: [],

        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
      },
      is_paid: null,
      status_list: [
        {id: 1, title: this.$t('paid')},
        {id: 2, title: this.$t('partial_paid')},
        {id: 3, title: this.$t('unpaid')},
        // {id: 4, title: this.$t('refund')},
        {id: 5, title: this.$t('overdue')},
      ],

      data: [],
      suppliers: [],
      branches: [],
      suppliers_ids: [],
      users: [],
      user: null,
      item: null,
      employees: [],
      items: [],
      branches_ids: [],
      branch: [],

      isPeriod: true,
      periodLabel: 'day',
      tab: this.$route.query.tab ? this.$route.query.tab : null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 1,
      page_summary: 1,
      f_by: 'name',
    }
  },
  watch: {
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
    "filters.filter_by": function (val) {
      if (val)
        this.doFilter();
      switch (val) {
        case 'daily':
          this.isPeriod = true;
          this.periodLabel = 'day';
          break;
        case 'weekly':
          this.isPeriod = true;
          this.periodLabel = 'week';
          break;
        case 'monthly':
          this.isPeriod = true;
          this.periodLabel = 'month';
          break;
        case 'yearly':
          this.isPeriod = true;
          this.periodLabel = 'year';
          break;
        case 'supplier':
          this.isPeriod = false;
          this.periodLabel = 'supplier';
          break;
        case 'user':
          this.isPeriod = false;
          this.periodLabel = 'user';
          break;
        default:
          this.isPeriod = false;
          this.periodLabel = '';
          break;
      }
    },

    is_paid: function (val) {
      if (val == 5) {
        this.filters.f_status = null;
        this.filters.overdue = 1;
      } else {
        this.filters.f_status = val;
        this.filters.overdue = null;
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.purchases_reports'), route: '/reports/purchase-reports-links'}, {title: this.$t("MENU.purchases_invoices_segmented_Reports")}]);
    this.filters.filter_by = this.$route.query.filter ? this.$route.query.filter : 'daily';

    // this.getEmployees();
    // this.getAuthUser();
    this.getBranches();
    this.getUsers();
  },
  methods: {
    ...cssStypeForPrintReport,
    salesInvoiceExport() {
      this.setReportLog('excel', 'purchases invoices segmented reports');
      let _footer = {
        label: this.$t('purchase_report.total') + ` (${this.currency_name})`,
        paid: this.summary_total_sum.sum_paid,
        unpaid: this.summary_total_sum.sum_unpaid,
        total: this.summary_total_sum.sum_total,
      };
      let _data = [];
      this.data_report_summary.forEach((row) => {
        _data.push({
          label: row.label,
          paid: row.paid,
          unpaid: row.unpaid,
          total: row.total,
        });
      });
      _data.push(_footer);

      return _data;
    },
    salesInvoiceDetailsExport() {
      let _footer = {
        id: this.$t('purchase_report.total') + ` (${this.currency_name})`,
        supplier_name: '',
        date: '',
        added_by: '',
        paid: this.details_total_sum.sum_paid_total,
        unpaid: this.details_total_sum.sum_unpaid_total,
        total: this.details_total_sum.sum_total_total,
      };
      let _data = [];
      this.data_report_details.forEach((row) => {
        _data.push({
          id: row.label,
          supplier_name: '',
          date: '',
          added_by: '',
          paid: '',
          unpaid: '',
          total: '',
        });
        row.details.forEach((sub_row) => {
          _data.push({
            id: sub_row.id,
            supplier_name: sub_row.label,
            date: sub_row.date,
            added_by: sub_row.added_by,
            paid: sub_row.paid,
            unpaid: sub_row.unpaid,
            total: sub_row.total,
          });
        });
        _data.push({
          id: this.$t('purchase_report.total') + ` (${this.currency_name})`,
          supplier_name: '',
          date: '',
          added_by: '',
          paid: '',
          unpaid: '',
          total: row.total.sum_total,
        });
      });
      _data.push(_footer);

      return _data;
    },

    getRandom() {
      return Math.floor(Math.random() * 100000000);
    },

    // fnExcelReport() {
    //     let table = document.getElementById('revenue_invoice');
    //     TableToExcel.convert(table, { // html code may contain multiple tables so here we are refering to 1st table tag
    //         name: this.$t('purchase_report')+'.xlsx', // fileName you could use any name
    //         sheet: {
    //             name: 'Sheet 1' // sheetName
    //         }
    //     });
    // },
    doFilter() {
      this.page = this.page + 1;
      this.page_summary = this.page_summary + 1;

      this.getReportInvoiceDetails();
      this.getReportInvoiceSummary();

    },
    resetFilter() {
      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.financial_year = 0;

      this.filters.suppliers_ids = null;
      this.filters.branches_ids = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.user_id = null;
      this.filters.item_id = null;
      this.filters.f_status = null;
      this.filters.overdue = null;

      this.branches_ids = null;
      this.suppliers_ids = null;
      this.user = null;
      this.item = null;
      this.is_paid = null;

      this.doFilter();
    },
    getIds(related_with){
      switch (related_with) {
        case 'branches':
          this.filters.branches_ids = this.branches_ids.map((row) => row.id);
          break;
        case 'suppliers':
          this.filters.suppliers_ids = this.suppliers_ids.map((row) => row.id);
          break;
        case 'item':
          this.filters.item_id = this.item.id ? this.item.id : null;
          break;
      }

    },
    changeIntervalType(action_type, range = null){
      if (this.filters.period == action_type){
        this.filters.period = null;
        this.filters.f_time_period = 0;
      }else{
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

      // this.filters.range = range;
      // if (range == 1){
      //   this.inner_period = 'last_'+action_type;
      //   if (action_type == 'year')
      //     this.filters.range = this.$moment().subtract(1, 'year').year();
      // }else{
      //   this.inner_period = action_type;
      // }


      // this.period_counter = [];
      // if (this.filters.period){
      //   let _counter = this.period_numbers[this.filters.period];
      //   if (this.filters.period == 'year') {
      //     let _current_year = new Date().getFullYear();
      //     this.period_counter.push(parseInt(_current_year));
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(parseInt(_current_year) - cc);
      //     }
      //   }else {
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(cc);
      //     }
      //   }
      //
      // }else {
      //   this.period_counter = [];
      // }

    },
    // getStatusList() {
    //     ApiService.get(this.mainRouteDependency + "/status").then((response) => {
    //         this.status_list = response.data.data;
    //     });
    // },

    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getUsers() {
        ApiService.get(this.mainRouteDependency + "/users").then((response) => {
          this.users = response.data.data;
        });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    // getAuthUser() {
    //   ApiService.get(`/get_auth`).then((response) => {
    //     this.currency_name = response.data.data.currency_name;
    //   });
    // },
    getEmployees() {
      ApiService.get(this.mainRouteDependency + "/employees").then((response) => {
        this.employees = response.data.data;
      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    loadMore() {
      this.page = this.page + 1;
      this.getReportInvoiceDetails();
    },
    loadMoreSummary() {
      this.page_summary = this.page_summary + 1;
      this.getReportInvoiceSummary();
    },

    /*****
     * details
     */
    // getLineChartInvoiceDetails() {
    //   ApiService.get(`${this.mainRoute}/details_chart`, {params: {...this.filters, page: (this.page ? this.page : 1), type: 'bar'}}).then((response) => {
    //     this.line_invoice_details = response.data;
    //   });
    // },
    // getPaidUnpaidInvoiceDetails() {
    //   ApiService.get(`${this.mainRoute}/details_chart`, {params: {...this.filters, page: (this.page ? this.page : 1), type: 'donut'}}).then((response) => {
    //     this.paid_unpaid_details = response.data.data;
    //   });
    // },
    getReportInvoiceDetails() {
      let _limit = (this.page ? this.page : 1) * 15 ;
      ApiService.get(`${this.mainRoute}/details`, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_report_details = response.data.data.data.items;
        this.details_total_sum = response.data.data.data.total;
      });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(`${this.mainRoute}/details`, {params: {...this.filters, limit: 99999999999999}}).then((response) => {
        this.dataDetailsList = response.data.data.data.items;
        this.totalsDetailsList = response.data.data.data.total;
        this.convertTableToExcelReport(tableId);
      });
    },

    /**
     * get for summary tab
     */
    getReportInvoiceSummary() {
      let _limit = (this.page_summary ? this.page_summary : 1) * 15 ;
      ApiService.get(`/v2${this.mainRoute}/summary`, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_report_summary = response.data.data.items;
        this.summary_total_sum = response.data.data.total;
      });
    },

    getAllDataForExportSummary(tableId, _export) {
      ApiService.get(`/v2${this.mainRoute}/summary`, {params: {...this.filters, limit: 99999999999999}}).then((response) => {
        this.dataSummaryList = response.data.data.items;
        this.totalsSummaryList = response.data.data.total;
          this.convertTableToExcelReport(tableId);
      });
    },

    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'purchases invoices segmented reports');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.purchases_invoices_segmented_Reports') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    printData(tableId) {
      this.setReportLog('pdf', 'purchases invoices segmented reports');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'purchases invoices segmented reports');

      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('MENU.purchases_invoices_segmented_Reports'), this.$t('MENU.purchases_invoices_segmented_Reports'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }

  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>